<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="dates"
    :disabled="disabled"
    transition="scale-transition"
    offset-y
    min-width="auto"
    @input="onInput"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="formatedDate"
        append-icon="mdi-calendar"
        background-color="white"
        readonly
        v-bind="attrs"
        outlined
        hide-details
        :disabled="disabled"
        :dense="dense"
        :label="label"
        v-on="on"
      ></v-text-field>
    </template>
    <div class="d-flex flex-row">
      <div v-if="type !== 'month'" class="d-flex flex-column pa-4" style="background-color: #334A58">
        <span class="mb-2" style="color: white; font-weight: bold">{{ label }}</span>
        <span class="pre-selected-date-btn" :class="{ 'active-pre-selected-date': todayDate }" @click="setToday()">Hoje</span>
        <span class="pre-selected-date-btn" :class="{ 'active-pre-selected-date': thisWeek }" @click="setThisWeek()">Esta semana</span>
        <span class="pre-selected-date-btn" :class="{ 'active-pre-selected-date': thisMonth }" @click="setThisMonth()">Este mês</span>
        <span class="pre-selected-date-btn" :class="{ 'active-pre-selected-date': lastMonth }" @click="setLastMonth()">Mês passado</span>
        <span class="pre-selected-date-btn" :class="{ 'active-pre-selected-date': !todayDate && !thisWeek && !thisMonth && !lastMonth }" @click="setToday()"
          >Personalizado</span
        >
      </div>
      <v-date-picker
        v-if="type === 'range'"
        v-model="dates"
        background-color="white"
        scrollable
        no-title
        locale="pt-br"
        range
        :disabled="disabled"
        :min="min"
        :max="max"
      >
        <v-spacer></v-spacer>
        <mf-button label="Limpar" outlined color="error" text @click="clearDates" />
        <mf-button color="primary" label="Aplicar" @click="saveAndClose" />
      </v-date-picker>
      <v-date-picker
        v-else
        v-model="singleDate"
        background-color="white"
        :type="type"
        scrollable
        no-title
        locale="pt-br"
        :disabled="disabled"
        :min="min"
        :max="max"
      >
        <v-spacer></v-spacer>
        <mf-button label="Limpar" outlined color="error" text @click="clearDates" />
        <mf-button color="primary" label="Aplicar" @click="saveAndClose" />
      </v-date-picker>
    </div>
  </v-menu>
</template>

<script>
import { DateTime } from 'luxon'

export default {
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: [Array, String]
    },
    type: {
      type: String,
      default: 'date',
      validator: value => ['date', 'month', 'range'].includes(value)
    },
    label: {
      type: String,
      default: ''
    },
    dense: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    min: {
      type: String,
      default: null
    },
    max: {
      type: String,
      default: null
    }
  },
  data: () => ({
    dates: [],
    singleDate: '',
    menu: false
  }),
  computed: {
    todayDate() {
      const today = DateTime.now().toISODate()
      return this.type === 'range' ? this.dates[0] === today && this.dates[1] === today : this.singleDate === today
    },
    thisWeek() {
      const startOfWeek = DateTime.now()
        .startOf('week')
        .toISODate()
      const endOfWeek = DateTime.now()
        .endOf('week')
        .toISODate()
      return this.type === 'range' ? this.dates[0] === startOfWeek && this.dates[1] === endOfWeek : this.singleDate === startOfWeek
    },
    thisMonth() {
      const startOfMonth = DateTime.now()
        .startOf('month')
        .toISODate()
      const endOfMonth = DateTime.now()
        .endOf('month')
        .toISODate()
      return this.type === 'range' ? this.dates[0] === startOfMonth && this.dates[1] === endOfMonth : this.singleDate === startOfMonth
    },
    lastMonth() {
      const startOfLastMonth = DateTime.now()
        .minus({ months: 1 })
        .startOf('month')
        .toISODate()
      const endOfLastMonth = DateTime.now()
        .minus({ months: 1 })
        .endOf('month')
        .toISODate()
      return this.type === 'range' ? this.dates[0] === startOfLastMonth && this.dates[1] === endOfLastMonth : this.singleDate === startOfLastMonth
    },
    formatedDate() {
      switch (this.type) {
        case 'range':
          if (!this.dates) return ''
          const startDate = DateTime.fromISO(this.dates[0])
          const endDate = DateTime.fromISO(this.dates[1])
          return `${startDate.isValid ? startDate.toFormat('dd/MM/yy') : ''} - ${endDate.isValid ? endDate.toFormat('dd/MM/yy') : ''}`
        case 'month':
          if (!this.singleDate) return ''
          return DateTime.fromISO(this.singleDate)
            .setLocale('pt-br')
            .toFormat('MMMM yyyy')
        case 'date':
        default:
          if (!this.singleDate) return ''
          return DateTime.fromISO(this.singleDate).toFormat('dd/MM/yy')
      }
    }
  },
  watch: {
    value(newValue, oldValue) {
      this.type === 'range' ? (this.dates = newValue) : (this.singleDate = newValue)
    }
  },
  beforeMount() {
    if (this.value) this.type === 'range' ? (this.dates = this.value) : (this.singleDate = this.value)
  },
  methods: {
    setToday() {
      const today = DateTime.now().toISODate()
      this.type === 'range' ? (this.dates = [today, today]) : (this.singleDate = today)
    },
    setThisWeek() {
      const startOfWeek = DateTime.now()
        .startOf('week')
        .toISODate()
      const endOfWeek = DateTime.now()
        .endOf('week')
        .toISODate()
      this.type === 'range' ? (this.dates = [startOfWeek, endOfWeek]) : (this.singleDate = startOfWeek)
    },
    setThisMonth() {
      const startOfMonth = DateTime.now()
        .startOf('month')
        .toISODate()
      const endOfMonth = DateTime.now()
        .endOf('month')
        .toISODate()
      this.type === 'range' ? (this.dates = [startOfMonth, endOfMonth]) : (this.singleDate = startOfMonth)
    },
    setLastMonth() {
      const startOfMonth = DateTime.now()
        .minus({ months: 1 })
        .startOf('month')
        .toISODate()
      const endOfMonth = DateTime.now()
        .minus({ months: 1 })
        .endOf('month')
        .toISODate()
      this.type === 'range' ? (this.dates = [startOfMonth, endOfMonth]) : (this.singleDate = startOfMonth)
    },
    clearDates() {
      this.type === 'range' ? (this.dates = []) : (this.singleDate = '')
      this.saveAndClose()
    },
    saveAndClose() {
      const value = this.type === 'range' ? this.dates : this.singleDate
      this.menu = false
      this.$refs.menu.save(value)
      this.$emit('change', value)
    },
    onInput(input) {
      const closing = !input
      if (closing) {
        this.saveAndClose()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.pre-selected-date-btn {
  color: white;
  margin: 4px 0;
  padding: 6px 8px;
  border-radius: 8px;
  font-size: 14px;
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.103);
  }
}

.active-pre-selected-date {
  background-color: white;
  color: #334a58;

  &:hover {
    background-color: white;
    color: #334a58;
  }
}
</style>
